import { render, staticRenderFns } from "./ExercisePanel.vue?vue&type=template&id=4148f97d&scoped=true&"
import script from "./ExercisePanel.vue?vue&type=script&lang=js&"
export * from "./ExercisePanel.vue?vue&type=script&lang=js&"
import style0 from "./ExercisePanel.vue?vue&type=style&index=0&id=4148f97d&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4148f97d",
  null
  
)

export default component.exports