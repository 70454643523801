<template>
  <div>
    <div class="d-flex align-items-center mb-1">
      <div class="flex-fill" />
      <span class="font-medium-2">Задание {{ !isFinished ? currentExercise + 1 : currentExercise }} из {{ exercises.length }}</span>
    </div>
    <template v-if="isFinished">
      <div class="d-flex justify-content-center">
        <h2>Вы завершили упражнение!</h2>
      </div>
    </template>
    <template v-else>
      <div
        v-for="(exercise, key) in exercises"
        :key="exercise.id"
      >
        <exercise-panel
          v-if="key === currentExercise"
          :exercise="exercise"
          @onNext="() => onNext(key)"
        />
      </div>
    </template>
  </div>
</template>

<script>
import ExercisePanel from './ExercisePanel.vue'

export default {
  name: 'MatchingExercises',
  components: { ExercisePanel },
  props: {
    exercises: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    results: {},
    currentExercise: null,
  }),
  computed: {
    isFinished() {
      return this.currentExercise === this.exercises.length
    },
  },
  mounted() {
    this.startExercise()
  },
  methods: {
    startExercise() {
      this.currentExercise = 0
    },
    onNext(index) {
      // eslint-disable-next-line no-unused-vars
      const exercise = this.exercises[index]
      // eslint-disable-next-line no-empty
      if (this.isFinished) {

      } else {
        this.currentExercise += 1
      }
    },
  },
}
</script>

<style scoped>

</style>
