<template>
  <div>
    <div class="mb-50 d-flex align-items-center justify-content-between">
      <p class="font-weight-bold mb-0">
        Заголовок
      </p>
      <b-button
        v-if="!page.toDelete"
        variant="flat-danger"
        size="sm"
        @click="onRemove"
      >
        <feather-icon
          icon="XIcon"
          class="mr-50"
        />
        Удалить задание
      </b-button>
    </div>
    <b-overlay
      variant="white"
      :show="page.toDelete"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <template #overlay>
        <b-button
          variant="outline-secondary"
          @click="onRestore"
        >
          <feather-icon
            icon="RefreshCcwIcon"
            class="mr-50"
          />
          Восстановить задание
        </b-button>
      </template>

      <b-input-group class="mb-2">
        <b-form-input
          v-model="page.title"
          placeholder="Введите заголовок"
          @blur="onChange"
        />
      </b-input-group>

      <p class="font-weight-bold">
        Инструкция (необязательно)
      </p>
      <b-input-group class="mb-2">
        <b-form-textarea
          v-model="page.instruction"
          @blur="onChange"
        />
      </b-input-group>

      <div>
        <p class="font-weight-bold">
          Определения
        </p>
        <div class="thin-divider mb-1" />
        <b-row
          no-gutters
          class="mb-1"
        >
          <b-col cols>
            Ключевое слово
          </b-col>
          <b-col
            cols="auto"
            style="width: 33px"
          />
          <b-col cols>
            Ответ
          </b-col>
          <b-col
            cols="auto"
            style="width: 50px"
          />
        </b-row>
        <div
          v-for="(row, key) in rows"
          :key="`row-${key}`"
        >
          <b-row
            class="mb-1"
            no-gutters
          >
            <b-col cols>
              <b-input-group>
                <b-form-input
                  v-model="row.question"
                  placeholder="Ключевое слово"
                  @blur="onChange"
                />
              </b-input-group>
            </b-col>
            <b-col
              cols="auto"
              align-self="center"
            >
              <span class="mx-1"> - </span>
            </b-col>
            <b-col cols>
              <b-input-group>
                <b-form-input
                  v-model="row.answer"
                  placeholder="Определение"
                  @blur="onChange"
                />
              </b-input-group>
            </b-col>
            <b-col cols="auto">
              <b-button
                variant="flat-danger"
                class="btn-icon ml-1"
                title="Удалить"
                :disabled="rows.length < 2"
                @click="() => removeRow(key)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </b-col>
          </b-row>
        </div>
        <div>
          <b-button
            variant="flat-secondary"
            @click="addRow"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            Добавить определение
          </b-button>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BFormInput, BFormTextarea, BInputGroup, BButton, BRow, BCol, BOverlay,
} from 'bootstrap-vue'

export default {
  name: 'PageForm',
  components: {
    BInputGroup, BFormInput, BFormTextarea, BButton, BRow, BCol, BOverlay,
  },
  props: {
    selectedPage: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    page: {
      title: '',
      instruction: '',
      rows: [],
    },
  }),
  computed: {
    rows() {
      return this.page.rows
    },
  },
  watch: {
    selectedPage() {
      this.setPage()
    },
  },
  mounted() {
    this.setPage()
  },
  methods: {
    onChange() {
      this.$emit('onChange', this.page)
    },
    onRemove() {
      this.page.toDelete = true
      this.onChange()
    },
    onRestore() {
      this.page.toDelete = false
      this.onChange()
    },
    setPage() {
      this.page = { ...this.selectedPage }
      if (!this.rows.length) this.addRow()
    },
    addRow() {
      this.page.rows.push({
        question: '',
        answer: '',
      })
    },
    removeRow(index) {
      this.page.rows.splice(index, 1)
    },
  },
}
</script>

<style scoped>

</style>
