<template>
  <div>
    <editor-header
      :editable="editable"
      :exercise="exercise"
      :saving="saving"
      @onBack="$emit('openSelector')"
      @onSave="saveExercise"
      @onPreview="setPreview"
    />
    <b-card>
      <template v-if="isPreview">
        <matching-exercises :exercises="actualPages" />
      </template>
      <template v-else>
        <pages-nav
          ref="navTabs"
          :pages="pages"
          :editable="editable"
          @onPageSelect="setPage"
          @onNewPage="addPage"
        >
          <template #form>
            <page-form
              :key="selectedPageIndex"
              :selected-page="pages[selectedPageIndex]"
              @onChange="(pg) => $set(pages, selectedPageIndex, {...pg})"
            />
          </template>
        </pages-nav>
      </template>
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import MatchingExercises from './MatchingExercises.vue'
import PagesNav from './editor/PagesNav.vue'
import PageForm from './editor/PageForm.vue'
import EditorHeader from './editor/EditorHeader.vue'

export default {
  name: 'MatchingExerciseEditor',
  components: {
    PageForm,
    PagesNav,
    MatchingExercises,
    EditorHeader,
    BCard,
  },
  props: {
    exercise: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isPreview: false,
    selectedPageIndex: 0,
    saving: false,
    pages: [],
  }),
  computed: {
    actualPages() {
      return this.pages.filter(page => !page.toDelete)
    },
  },
  mounted() {
    this.pages = this.exercise?.exercisesContents?.map(({ id, data }) => {
      const parsedData = JSON.parse(data)
      return { id, ...parsedData }
    })
    if (!this.pages?.length) this.addPage()
  },
  methods: {
    addPage() {
      this.pages.push({
        title: '',
        instruction: '',
        rows: [],
      })
      setTimeout(() => {
        if (this.$refs.navTabs) this.$refs.navTabs.setLast()
      })
    },
    setPage(page) {
      this.selectedPageIndex = page
    },
    setPreview(preview) {
      this.isPreview = preview
    },
    async saveExercise() {
      this.saving = true
      this.pages = await Promise.all(this.pages.map(async page => {
        const { id, toDelete } = page
        if (id) {
          if (toDelete) await this.$http.delete('/exercises-content/delete', { params: { id } })
          else await this.$http.put('/exercises-content/update', { data: JSON.stringify(page) }, { params: { id } })
        } else {
          const data = JSON.stringify({ title: page.title, instruction: page.instruction, rows: page.rows })
          const createdPage = await this.$http.post('/exercises-content/create', { exercises_id: this.exercise.id, data })
          return { ...page, id: createdPage?.id }
        }
        return page
      }))

      this.pages = this.pages.filter(page => !page.toDelete)

      this.saving = false
    },
  },
}
</script>

<style scoped>

</style>
