<template>
  <div>
    <div class="d-flex align-items-center mb-1">
      <b-button
        variant="flat-secondary"
        type="button"
        size="sm"
        @click="$emit('onBack')"
      >
        <feather-icon
          class="mr-50"
          icon="ArrowLeftIcon"
        />
        Назад
      </b-button>
      <div class="flex-fill" />
      <b-button
        v-if="editable"
        :variant="isPreview ? 'flat-primary' : 'flat-secondary'"
        class="mr-1"
        size="sm"
        @click="isPreview = !isPreview"
      >
        <feather-icon
          class="mr-50"
          :icon="isPreview ? 'EyeOffIcon' : 'EyeIcon'"
        />
        Предпросмотр
      </b-button>
      <create-exercise-modal
        v-if="editable"
        :exercise="exercise"
      >
        <template #activator="{show}">
          <b-button
            class="mr-1"
            size="sm"
            variant="outline-primary"
            @click="show"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            Настроить
          </b-button>
        </template>
      </create-exercise-modal>
      <b-button
        variant="success"
        type="submit"
        size="sm"
        :disabled="saving"
        @click="$emit('onSave')"
      >
        <template v-if="!saving">
          <feather-icon
            class="mr-50"
            icon="CheckIcon"
          />
          Сохранить
        </template>
        <template v-else>
          <b-spinner small />
          <span class="ml-50">Сохранение...</span>
        </template>
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'
import CreateExerciseModal from '../../../exercise/CreateExerciseModal.vue'

export default {
  name: 'EditorHeader',
  components: { CreateExerciseModal, BButton, BSpinner },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    saving: {
      type: Boolean,
      default: false,
    },
    exercise: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isPreview: false,
  }),
  watch: {
    isPreview() {
      this.$emit('onPreview', this.isPreview)
    },
  },
}
</script>

<style scoped>

</style>
