<template>
  <div>
    <h3>{{ exercise.title }}</h3>
    <p>{{ exercise.instruction }}</p>

    <draggable
      id="variants"
      :list="variants"
      draggable=".variant"
      group="a"
      class="variants-wrapper border-secondary"
      v-bind="dragOptions"
      :force-fallback="true"
      @start="onStart"
      @end="onEnd"
    >
      <b-button
        v-for="(variant, key) in variants"
        :key="`variant-${key}`"
        variant="primary"
        class="variant"
      >
        {{ variant }}
      </b-button>
    </draggable>

    <div class="mt-4" />

    <div v-if="questions">
      <div
        v-for="({ question, answer, isCorrect }, key) in questions"
        :key="`question-${key}`"
        class="row-wrapper"
      >
        <draggable
          v-bind="dragOptions"
          :id="`answers-${key}`"
          :list="answer"
          draggable=".answer"
          :force-fallback="true"
          :group="isMoving && isMovingFrom !== `answers-${key}` && !!answer.length ? `group-${key}` : 'a'"
          class="border-secondary answer-wrapper"
          @start="onStart"
          @end="onEnd"
        >
          <b-button
            v-for="(ans, key) in answer"
            :key="`answer-${key}`"
            class="answer"
            :variant="colorByResult(isCorrect)"
          >
            {{ ans }}
          </b-button>
        </draggable>
        <div class="question-wrapper">
          <p>- {{ question }}</p>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center w-100 mt-2">
      <b-button
        v-if="!isDone"
        size="lg"
        variant="success"
        :disabled="!isAllMoved"
        @click="checkResult"
      >
        <template v-if="!isAllMoved">
          Сперва перетащите все плитки
        </template>
        <template v-else>
          <feather-icon
            icon="CheckIcon"
            class="mr-50"
            size="20"
          />
          Готово
        </template>
      </b-button>
      <template v-else>
        <b-button
          v-if="hasMistakes"
          variant="warning"
          size="lg"
          @click="reset"
        >
          <feather-icon
            icon="RefreshCcwIcon"
            class="mr-50"
            size="20"
          />
          Попробовать снова
        </b-button>
        <b-button
          v-else
          variant="info"
          size="lg"
          @click="next"
        >
          Далее
          <feather-icon
            icon="ArrowRightIcon"
            class="ml-50"
            size="20"
          />
        </b-button>
      </template>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import draggable from 'vuedraggable'
import { shuffleArray } from '../../../../helpers'

export default {
  name: 'ExercisePanel',
  components: {
    draggable,
    BButton,
  },
  props: {
    exercise: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    variants: [],
    questions: [],
    isMoving: false,
    isMovingFrom: null,
    isDone: false,
  }),
  computed: {
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    isAllMoved() {
      return !this.variants.length
    },
    hasMistakes() {
      return !!this.questions.filter(({ isCorrect }) => isCorrect === false).length
    },
  },
  mounted() {
    this.setVariants()
    this.setQuestions()
  },
  methods: {
    colorByResult(isCorrect) {
      if (isCorrect === true) return 'success'
      if (isCorrect === false) return 'danger'
      return 'primary'
    },
    onStart(props) {
      this.isMoving = true
      this.isMovingFrom = props.from.id
    },
    onEnd() {
      this.isMoving = false
      this.isMovingFrom = null
    },
    setQuestions() {
      this.questions = this.exercise.rows.filter(({ answer, question }) => answer && question).map(row => ({ question: row.answer, answer: [], isCorrect: null }))
    },
    setVariants() {
      this.variants = shuffleArray(this.exercise.rows.filter(({ answer, question }) => answer && question).map(row => row.question))
    },
    getAnswerByQuestion(question) {
      return this.exercise.rows.find(row => row.answer === question)
    },
    checkResult() {
      this.questions = this.questions.map(row => {
        const updatedRow = { ...row }
        const { question, answer } = row
        const givenAnswer = answer[0] ?? null
        const { question: correctAnswer } = this.getAnswerByQuestion(question)

        return { ...updatedRow, isCorrect: correctAnswer === givenAnswer }
      })
      this.isDone = true
    },
    reset() {
      this.setQuestions()
      this.setVariants()
      this.isDone = false
    },
    next() {
      this.$emit('onNext')
    },
  },
}
</script>
<style scoped lang="scss">
.variants-wrapper {
  width: 100%;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, 150px);
  min-height: 81px;
  padding: 5px;
  border-radius: 6px;
  background: #f9f9f9;

  .btn {
    min-width: 150px;
    min-height: 70px;
    cursor: grab;

    &.sortable-chosen {
      cursor: grabbing;
    }
  }
}

.row-wrapper {
  display: flex;
  align-items: center;
  width: 100%;

  .answer-wrapper {
    background: #f9f9f9;
    border-radius: 8px;
    width: 150px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    margin-bottom: 1rem;

    .btn {
      width: 149px;
      height: 69px;
      cursor: grabbing;
      &.sortable-chosen {
        cursor: grabbing;
      }
    }
  }

  .question-wrapper {

    p {
      margin: 0;
      font-weight: bold;
      font-size: 20px;
    }
  }
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
}

</style>
