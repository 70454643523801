<template>
  <div v-if="exercise">
    <create-exercise
      v-if="
        exercise.exercise_types_id === EXERCISE_TYPE_BUTTON"
      :existing-exercise="exercise"
      :loading="loadingExercise"
      :editable="canEditExercise"
      @openSelector="() => $router.push({name: 'cabinet.exercises'})"
    />
    <matching-exercise-editor
      v-else-if="exercise.exercise_types_id === EXERCISE_TYPE_MATCHING"
      :exercise="exercise"
      :editable="canEditExercise"
      @openSelector="() => $router.push({name: 'cabinet.exercises'})"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { adminRole, teacherRole, userRoles } from '../../store/user'
import CreateExercise from '@/components/new_courses/course/theme/lesson/step/components/elements/exercise/editor/CreateExercise.vue'
import { EXERCISE_TYPE_BUTTON, EXERCISE_TYPE_MATCHING } from '../../constants'
import MatchingExerciseEditor from '../../components/exercise/games/matching/MatchingExerciseEditor.vue'

export default {
  components: {
    MatchingExerciseEditor,
    CreateExercise,
  },
  data: () => ({
    exercise: null,
    loadingExercise: true,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    EXERCISE_TYPE_BUTTON() {
      return EXERCISE_TYPE_BUTTON
    },
    EXERCISE_TYPE_MATCHING() {
      return EXERCISE_TYPE_MATCHING
    },
    exercise_id() {
      return this.$route.params.exercise_id
    },
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    isAdmin() {
      return this.userRole === adminRole
    },
    canEditExercise() {
      if (!this.exercise || this.isAdmin) return true
      return this.exercise.user_id === this.user.id && this.$can('edit', 'exercises')
    },
  },
  watch: {
    $route() {
      this.getExercise()
    },
  },
  async beforeMount() {
    if (this.exercise_id) {
      this.$eventBus.$emit('page-title', 'Загрузка...')
      await this.getExercise()
      this.$eventBus.$emit('page-title', this.exercise.name)
    }
    this.loadingExercise = false
  },
  methods: {
    async getExercise() {
      this.loadingExercise = true
      this.exercise = await this.$http.get(`/exercises/view?id=${this.exercise_id}&expand=user,exercisesContents,exercisesTags.tags`)
      this.loadingExercise = false
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-knowledge-base.scss';
</style>
