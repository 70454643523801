<template>
  <b-tabs
    v-model="selectedPage"
    @input="pageSelected"
  >
    <b-tab
      v-for="(page, key) in pages"
      :key="`editor-${key}`"
      :title="`${key + 1}`"
      nav-class="align-items-center"
    >
      <slot name="form" />
    </b-tab>

    <template #tabs-end>
      <b-button
        v-if="editable"
        class="btn-icon"
        size="sm"
        variant="flat-primary"
        pill
        @click.prevent="addPage"
      >
        <feather-icon icon="PlusIcon" />
      </b-button>
    </template>
  </b-tabs>
</template>

<script>
import {
  BButton, BTab, BTabs,
} from 'bootstrap-vue'

export default {
  name: 'PagesNav',
  components: {
    BTabs, BTab, BButton,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    pages: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    selectedPage: 0,
  }),
  methods: {
    addPage() {
      this.$emit('onNewPage')
    },
    setPage(index) {
      this.selectedPage = index
    },
    setLast() {
      this.selectedPage = this.pages.length - 1
    },
    pageSelected(page) {
      this.$emit('onPageSelect', page)
    },
  },
}
</script>

<style scoped>

</style>
